/**
 * Модуль работы со счетчиками избранного и корзины
 */
export function initHeaderCounters() {
  const favourites = document.querySelectorAll(".favourites-counter");
  const cart = document.querySelectorAll(".cart-counter");

  if (favourites && cart) {
    document.addEventListener("favouritesUpdate", (event) =>
      setCount(favourites, event.detail.count)
    );
    document.addEventListener("cartUpdate", (event) =>
      setCount(cart, event.detail.count)
    );
  }

  function setCount(elem, count) {
    elem.forEach((elem) => {
     if (count !== 0) {
      elem.innerHTML = count;
     } else {
      elem.innerHTML = '';
     }
    });
  }
}
