import './index.scss';

/*import {makeMarquee} from '../../scripts/modules/marquee.js';
import {initBurgerMenu} from '../../scripts/modules/burger-menu.js';*/
import {initHeaderCounters} from '../../scripts/modules/header-counters.js';
import { burgerTrigger, burgerMenu } from '../../scripts/modules/header.js';

document.addEventListener('DOMContentLoaded', function () {
  const marquees = document.querySelectorAll('.marquee2__content');

  if (marquees.length) {
    marquees.forEach(element => makeMarquee(element, element.dataset));
  }

  //initBurgerMenu();
  initHeaderCounters();
  //anchorShift();
  burgerTrigger();
  burgerMenu();
});

const footerForm = document.querySelector('.footer-input');
const footerInput = footerForm.querySelector('.footer-input__text');
const footerFormBtn = footerForm.querySelector('.footer-input__button');
const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

function isEmailValid(input) {
  return EMAIL_REGEXP.test(input.value) ?
  footerForm.style.borderColor = 'black' : footerForm.style.borderColor = '#ec3c3c';
}

footerFormBtn.addEventListener('click', () => {
  console.log('ddd');
  if (!footerForm.checkValidity()) {
    isEmailValid(footerInput);
  }

  footerInput.addEventListener('input', () => isEmailValid(footerInput));
});

const copirate = document.querySelector('.footer-lower__left span');
const now = new Date();
const year = now.getFullYear().toString().slice(-2);
copirate.textContent = year;
