export function anchorShift() {
  const anchorHeight = 50;

  document.querySelectorAll(".nav-link").forEach((anchor) => {
    anchor.addEventListener("click", function (event) {
      event.preventDefault();

      const targetElement = document.querySelector(this.getAttribute("href"));
      if (targetElement) {
        const targetOffsetTop = targetElement.offsetTop - anchorHeight;
        window.scrollTo({
          top: targetOffsetTop,
          behavior: "smooth",
        });
      }
    });
  });
}

export function burgerTrigger() {
  const burgerButton = document.querySelector(".header-burger");
  const burgerOpened = document.querySelector(".header-burger__opened");
  const burgerCross = document.querySelector(".burger-cross");

  burgerButton.addEventListener("click", () => {
    burgerOpened.style.display = "flex";
  });

  burgerCross.addEventListener("click", () => {
    burgerOpened.style.display = "none";
  });
}



export function burgerMenu() {
  const isMobile = window.innerWidth <= 768;
  const isTabletLandscape = window.innerWidth <= 932 && window.matchMedia("(orientation: landscape)").matches;
  const burgerItems = document.querySelectorAll(".burger-index");
  let isMobileOrTabletLandscape = isMobile || isTabletLandscape;

  function handleClick() {
    const item = this;
    burgerItems.forEach((otherItem) => {
      if (otherItem !== item) {
        otherItem.children[1].style.display = "none";
      }
    });
    item.children[1].style.display === "flex"
      ? (item.children[1].style.display = "none")
      : (item.children[1].style.display = "flex");
  }

  burgerItems.forEach((item) => {
    item.addEventListener("click", handleClick);
  });
  console.log('listeners added');

  window.addEventListener("resize", () => {
    const newIsMobile = window.innerWidth <= 768;
    const newIsTabletLandscape = window.innerWidth <= 932 && window.matchMedia("(orientation: landscape)").matches;
    const newIsMobileOrTabletLandscape = newIsMobile || newIsTabletLandscape;

    if (newIsMobileOrTabletLandscape !== isMobileOrTabletLandscape) {
      if (newIsMobileOrTabletLandscape) {
        burgerItems.forEach((item) => {
          item.addEventListener("click", handleClick);
        });
        console.log('listeners added');
      } else {
        burgerItems.forEach((item) => {
          item.removeEventListener("click", handleClick);
        });
        console.log('listeners removed');
      }
      isMobileOrTabletLandscape = newIsMobileOrTabletLandscape;
    }
  });
}